@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$fake-white: #f9fafb;
$fakeWhite: #dce8f59f;
$white: #ffffff;
$accent: #00adb5;
$grayAccent: #cae6e6;
$accentSofter: #cceff0;
$accentSoft: #e6f7f8;
$accentDarker: #00949c;
$blue: #0a58c2;
$babyBlue: #87ceeb;
$babyBlueDark: #6ca6cf;
$babyPink: #ffc0cb;
$babyPinkDark: #ffaeb9;
$red: #b51b09;
$accentRed: #bc3222;
$green: #0ac263;
$accentGreen: #6cdaa1;

// Function to generate color classes
@function color-class($color) {
  $color-map: (
    'white': $white,
    'fake-white': $fake-white,
    'fakeWhite': $fakeWhite,
    'accent': $accent,
    'blue': $blue,
    'red': $red,
    'green': $green,
    'accentSofter': $accentSofter,
    'accentSoft': $accentSoft,
    'accentDarker': $accentDarker,
  );

  @if map-has-key($color-map, $color) {
    $color-value: map-get($color-map, $color);
    @return (
      'text-#{$color}': (
        color: $color-value,
      ),
      'bg-#{$color}': (
        background-color: $color-value,
      )
    );
  } @else {
    @warn "Color '#{$color}' not found in color map.";
    @return ();
  }
}

// Usage
@each $color
  in (
    'fake-white',
    'fakeWhite',
    'accent',
    'blue',
    'red',
    'green',
    'accentSofter',
    'accentSoft',
    'accentDarker'
  )
{
  $color-classes: color-class($color);
  @each $class-name, $styles in $color-classes {
    .#{$class-name} {
      @each $property, $value in $styles {
        #{$property}: $value;
      }
    }
  }
}

@for $i from 100 through 900 {
  .fw-#{$i} {
    font-weight: #{$i};
  }
}

* {
  font-family: 'Poppins', sans-serif;
}
