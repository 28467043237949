@import '../../../public-scss/colors.scss';

.add-symptom-btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $blue !important;
    border-color: $blue !important;
    color: $fake-white !important;
  }

  background-color: $fake-white;
  color: $blue;
  border-color: $blue;
  font-weight: 700;
  border-width: 45%;
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  .add-symptom-icon {
    font-size: 1.5rem;
    transition: transform 0.3s ease-in-out;
    position: relative;
    right: 0.55rem;
    bottom: 0.3rem;
  }

  &:hover {
    background-color: $blue;
    color: $fake-white;
    border-color: $blue;

    .add-symptom-icon {
      transform: rotate(90deg);
    }
  }
}

.remove-symptom-btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $red !important;
    border-color: $red !important;
    color: $fake-white !important;
  }

  background-color: $fake-white;
  color: $red;
  border-color: $red;
  font-weight: 700;
  border-width: 45%;
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;

  .remove-symptom-icon {
    font-size: 1.5rem;
    position: relative;
    right: 0.55rem;
    bottom: 0.3rem;
  }

  &:hover {
    background-color: $red;
    color: $fake-white;
    border-color: $red;
  }
}

.error-message {
  color: $red !important;
  font-weight: 600;
}

.make-diagnostic-btn {
  transition: 0.5s;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accent !important;
    border-color: $accent !important;
    color: $white !important;
  }

  &:hover {
    background-color: $accentDarker;
    color: $white;
    border-color: $accentDarker;

    .make-diagnostic-icon {
      transform: scale(1.15);
      /* Increase icon size */
      opacity: 0.8;
      /* Reduce opacity */
    }
  }

  background-color: $accent;
  color: $white;
  border-color: $accent;
  font-weight: 700;
  border-width: 0.15rem;
  border-radius: 2rem;
  width: 18rem;
  height: 2.5rem;

  .make-diagnostic-icon {
    font-size: 1.5rem;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    /* Updated transition property */
    position: relative;
    transform: scale(1);
    /* Initial scale */
    opacity: 1;
    /* Initial opacity */
  }
}

.btn-validate-blood {
  transition: 0.3s;
  background-color: $fake-white;
  color: $accent;
  border-color: $accent;
  font-weight: 700;
  border-width: 0.15rem;
  border-radius: 2rem;
  width: 17rem;
  height: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:hover {
    background-color: $accent;
    color: $fake-white;
    border-color: $accent;
    text-decoration: none;
  }
}

.pulsate {
  border: 2px solid transparent $accent;
  cursor: pointer;
  animation: pulsateAnimation 2s infinite;
}

@keyframes pulsateAnimation {
  0%,
  100% {
    box-shadow: 0 0 0 0.2rem transparent;
  }

  50% {
    box-shadow: 0 0 0 0.4rem $accent;
  }
}

.btn-accept-blood {
  transition: 0.3s;
  background-color: $fake-white;
  color: $green;
  border-color: $accent;
  font-weight: 700;
  border-width: 0.15rem;
  border-radius: 2rem;
  width: 15rem;
  height: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:hover {
    background-color: $green;
    color: $fake-white;
    border-color: $accent;
    text-decoration: none;
  }
}

.pulsate-green {
  border: 2px solid transparent $green;
  cursor: pointer;
  animation: pulsateAnimation-green 2s infinite;
}

@keyframes pulsateAnimation-green {
  0%,
  100% {
    box-shadow: 0 0 0 0.2rem transparent;
  }

  50% {
    box-shadow: 0 0 0 0.4rem $green;
  }
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


.hiName {
  font-weight: 500 !important;
  font-size: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: gray;
}

.progressContainer {
  .active{
    background-color: $accent !important;
  }
  .completed{
    background-color: $accentSofter !important;
  }
}

.form_diagnostic {
  background-color: $white;
  padding: 1.5rem;
  border-radius: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  width: 40rem;


  .next_btn,
  .back_btn {
    transition: 0.3s;
    font-weight: 700;
    border-radius: 5rem;
    width: 10rem;
    height: 2.5rem;
    @media (max-width: 500px) {
      width: 8rem;
    }
  }

  .next_btn {
    background-color: $accent;
    color: $white;
    border: 1px solid $accent;

    svg {
      font-size: 1.35rem;
      transform: translateX(0);
      transition: transform 0.3s; /* Add a transition for the SVG */
    }

    &:hover,
    &:focus {
      background-color: $accentDarker !important;
      color: $fake-white !important;
      border-color: $accentDarker !important;

      svg {
        transform: translateX(75%);
      }
    }
  }

  .back_btn {
    background-color: $white;
    color: $accent;
    border: 1px solid $accent;
    svg {
      font-size: 1.35rem;
      transform: translateX(45%);
      transition: transform 0.3s; /* Add a transition for the SVG */
    }

    &:hover,
    &:focus {
      background-color: $accentDarker !important;
      color: $fake-white !important;
      border-color: $accentDarker !important;

      svg {
        transform: translateX(-50%);
      }
    }
  }
}

.selection {
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  background-color: $white;
  border-radius: 5rem;
  border-color: $accent !important;
  color: $accent;
  font-weight: 700;
  margin-top: 0.5rem;
  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accentDarker !important;
    color: $white !important;
    border-color: $accentDarker !important;
  }

  svg {
    font-size: 2rem;
  }
}

.male {
  background-color: $white !important;
  color: $babyBlue !important;
  border-color: $babyBlue !important;
  border-width: 2px;

  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $babyBlue !important;
    color: $white !important;
    border-color: $babyBlueDark !important;
  }
}
.female {
  background-color: $white !important;
  color: $babyPink !important;
  border-color: $babyPink !important;
  border-width: 2px;
  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $babyPink !important;
    color: $white !important;
    border-color: $babyPinkDark !important;
  }
}

.selected {
  font-weight: 900;
  background-color: $accent;
  color: $white;
}

.selectedMale {
  font-weight: 900;
  background-color: $babyBlueDark !important;
  color: $white !important;
  border-color: $babyBlueDark !important;
}
.selectedFemale {
  font-weight: 900;
  background-color: $babyPinkDark !important;
  color: $white !important;
  border-color: $babyPinkDark !important;
}

/* Define the CSS class for the pulsating glow effect */
.pulsateBtn {
  animation: pulsateBtn 2s infinite;
}

/* Define the keyframes for the pulsate animation */
@keyframes pulsateBtn {
  0% {
    box-shadow: 0 0 10px $accent, 0 0 20px $accent;
  }
  50% {
    box-shadow: none; /* No box shadow in the middle of the animation */
  }
  100% {
    box-shadow: 0 0 10px $accent, 0 0 20px $accent;
  }
}

#guidelines {
  border-radius: 1.5rem;
}

.addAnalysis {
  transition: 0.5s;
  background: none;
  font-weight: 700;
  border-radius: 2rem;
  width: 8rem;
  margin-top: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: $green;
  border: 2px solid $green;
  svg {
    transition: transform 0.3s ease-in-out;
    font-size: 1.5rem;
  }
  &:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accentGreen !important;
    color: $white !important;
    border-color: $accentGreen;
    svg {
      transform: rotate(90deg);
    }
  }
}
.removeAnalysis {
  transition: 0.5s;
  margin-left: 1rem;
  border: 1px solid $red;
  background: none;
  color: $red;
  border-radius: 5rem;
  padding-bottom: 0.175rem;

  &:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accentRed !important;
    color: $white !important;
    border-color: $accentRed;
  }
}

.analysisField {
  border-width: 2px;
  border-radius: 1rem;
  &:focus {
    outline: none !important;
    border-color: $accent !important;
    box-shadow: 0 0 1px 1px $accent !important;
  }
}

.hrForm {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.textFieldForm {
  transition: 0.5s;
  box-shadow: none;
  border-width: 2px;
  border-radius: 0.5rem;
  border: 1px solid $accentDarker;
  &:focus {
    outline: none !important;
    border-color: $accent !important;
    box-shadow: 0 0 1px 1px $accent !important;
  }
}

// TipsSection.scss

.tips-content {
  opacity: 1;
  max-height: 500px; // Adjust the max height as needed
  overflow: hidden;
  transition: max-height 0.6s ease-in-out, opacity 0.3s ease-in-out;

  ul {
    list-style: circle;
    padding-left: 20px;
  }
}

.tips-content.hide {
  max-height: 0;
  opacity: 0;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
  color: gray;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: lightgray;
  }
}
