@import '../../../public-scss/colors.scss';

.organizationManageContainer {
  max-width: 70rem !important; /* Use max-width instead of width */
  width: 100%; /* Allow it to take the full width of the parent */
  background: $white;
  border-radius: 3rem !important;
  h2 {
    font-size: 1.5rem;
  }

  .userTableContainer {
    border-radius: 1.5rem !important;
    border: 1px solid lightgray;
    h2 {
      font-size: 1.5rem;
    }

    table {
      width: 100%; /* Make the table take the full width of the container */
    }
  }
}

.monthYearSelector,
.daySelector {
  transition: 0.3s;
  width: 15rem;
  border-radius: 0.5rem;
  border: 2px solid $accent;
  background-color: $accentSofter;
  z-index: 501 !important;

  cursor: pointer;
  svg {
    font-size: 1.2rem;
  }

  &:hover {
    background-color: $accent;
    color: $fake-white;
    .dropdownContent {
      max-height: 100vh;
    }
  }
}

.daySelector {
  width: 10rem !important;
}

.dropdownContent {
  z-index: 500 !important;
  max-height: 20rem;
  overflow: hidden;
  height: auto;
  overflow-y: auto; /* Add this line to make the content scrollable */
  transition: max-height 0.3s ease-in-out;
  background-color: $accentSoft;
  border-radius: 0 0 0.5rem 0.5rem;
  border: 2px solid $accent;
  position: absolute;
  width: 15rem;
  top: 85%;
  left: 0.94rem;

  .dropdownItem {
    padding: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: $accent;
      color: white;
    }
  }
}

.dayContent {
  width: 10rem !important;
  left: 17.45rem !important;
}

.addUser {
  transition: 0.5s;
  background: none;
  font-weight: 700;
  border-radius: 2rem;
  width: 10rem;
  margin-top: 3rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: $green;
  border: 2px solid $green;
  svg {
    transition: transform 0.3s ease-in-out;
    font-size: 1.5rem;
  }
  &:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accentGreen !important;
    color: $white !important;
    border-color: $accentGreen;
    svg {
      transform: rotate(90deg);
    }
  }
}

.statusLabels {
  border-radius: 1rem !important;
  width: 8rem !important;
}

@media (max-width: 768px) {
  .organizationManageContainer {
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.25rem;
    }
  }
}
@media (max-width: 535px) {
  .monthYearSelector {
    z-index: 506 !important;
  }
  .dropdownContent {
    left: 0.93rem !important;
    top: 45% !important;
    z-index: 505 !important;
  }

  .dayContent {
    z-index: 503 !important;
    top: 95% !important;
  }
  .daySelector {
    z-index: 504 !important;
  }
}
