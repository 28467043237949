@import '../../../public-scss/colors.scss';

.resultBox {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
  width: 70rem !important;
  background: $white;
  border-radius: 3rem !important;
}

.interpretationsTextArea {
  h1 {
    padding-left: 2rem !important;
    padding-top: 1rem !important;
  }
}

.interpretationsVersions {
  h2 {
    padding-top: 1rem !important;
  }
}
.dataInterpretationBox {
  h2 {
    padding-left: 2rem !important;
    padding-top: 1rem !important;
  }
}
.versionBox {
  border-radius: 2.5rem;
  padding: 0.5rem;
  margin-left: 0.35rem;
  margin-right: 0.35rem;
  width: 20rem !important;
  max-height: 25rem !important;
  overflow-y: auto !important;
}

.versionBtn {
  border-radius: 2rem;
  background-color: $white !important;
  border: 1px solid $accent;
  margin-right: 1rem !important;
  margin-left: 1rem !important;
  width: 15rem !important;
  h3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-top: 1rem !important;
    font-size: 1rem !important;
  }
}

.selectedDiagnostic {
  background-color: $accent !important;
  color: $white !important;
  border: 1px solid lightgray !important;

  hr {
    background-color: $white !important;
  }
}

.diagnostic-prompt {
  background-color: white;
  border: 0.2rem $accent dashed;
  border-radius: 1rem;
}

.regenerateBtn,
.editBtn {
  transition: 0.3s;
  font-size: 1.2rem !important;
  background-color: $fake-white !important;
  border-radius: 2.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-top: 1rem !important;

  color: $accent !important;
  font-weight: 700;
  border: 1px solid $accent !important;
  svg {
    transition: 0.3s;
  }
  &:hover,
  &:focus {
    background-color: $accentDarker !important;
    color: $fake-white !important;
    border-color: $accentDarker !important;

    .roundArrow {
      transform: rotate(-90deg) !important;
    }
  }
}

.tips-content {
  opacity: 1;
  max-height: 500px; // Adjust the max height as needed
  overflow: hidden;
  transition: max-height 0.6s ease-in-out, opacity 0.3s ease-in-out;
  border-radius: 1.5rem;
  font-weight: 600;
}

.consultationDetailsContainer,
.petDetailsContainer {
  background-color: $fake-white !important;
  border-radius: 2rem !important;
  margin: 0rem !important;
  border: 0.5rem solid $white !important;
}

.resultUl {
  padding-inline-start: 0 !important;
  margin-bottom: 0 !important;
  li {
    list-style: circle !important;
  }
}

.editResultPageBtn{
  transition: 0.3s;
  background-color: $accentSofter;
  color: $accent;
  padding:0.25rem;
  font-weight: 600;
  margin-top: 0.75rem;
  margin-bottom: 0.6rem;
  height: 3rem;
  width: 6rem;
  border-radius: 0.5rem;
  border: $accentDarker 2px solid;
  font-size: 1.15rem;
  svg{
    transition: 0.3s;
    font-size: 1.75rem;
    color: $accent;
    font-weight: 600;

  }
  &:hover{
    background-color: $accent;
    color: $fake-white;
    svg{
      color: $fake-white;
    }
  }
}

.cancelResultPageBtn{
  transition: 0.3s;
  background-color: $accentRed;
  color: $fake-white;
  padding:0.25rem;
  font-weight: 600;
  margin-top: 0.75rem;
  margin-bottom: 0.6rem;
  height: 3rem;
  width: 8rem;
  border-radius: 0.5rem;
  border: $red 2px solid;
  font-size: 1.15rem;
  svg{
    transition: 0.3s;
    font-size: 1.75rem;
    color: $fake-white;    
    font-weight: 600;
  }
  &:hover{
    background-color: $fake-white;
    color: $accentRed;
    svg{
      color: $accentRed;
    }
  }
}

.selectEditConsultation,.inputFieldTextEditConsultation,.inputFieldTextAreaEditPet{
  width: 11rem;
}

.inputFieldTextAreaEditConsultation{
  width: 100%;
}

.inputFieldTextAreaEditConsultation,.inputFieldTextAreaEditPet,.selectEditConsultation,.inputFieldTextEditConsultation,.analysisField, .inputSupportPage{
  border-radius: 1rem;
  padding:0.5rem;
  border: 1px solid $accent;
  &:focus{
    outline: 2px solid $accentDarker;
  }
}

.addAnalysis{
  width: 12rem !important;
}

.characteristicsEditTextArea{
  width: 100%;
}