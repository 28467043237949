@import "../../../public-scss/colors.scss";

.lds-ring {
  display: inline-block;
  position: relative;
  width: 6em;
  height: 6em;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 6em;
  height: 6em;
  margin: 0.5em;
  border: 0.5em solid $accent;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $accent transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  margin: 1.5rem;
  font-weight: 700;
  color: $accent;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: inline-block;
  position: relative; /* Required for pseudo-element positioning */
}

/* Animation to fade in and out the text */
@keyframes pulsate {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Apply pulsating animation to dots */
.loader-text.active {
  animation: pulsate 1.5s infinite;
}



.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}