@import '../../../../public-scss/colors.scss';

.back-btn {
  &:focus,
  &:hover {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accentDarker !important;
    border-color: $accent !important;
    color: $fake-white !important;

    .back-btn-icon {
      transform: translateX(
        -0.5rem
      ); // Move the icon 5 pixels to the left on hover
    }
  }

  background-color: $accent;
  color: $fake-white;
  border-color: $accent;
  font-weight: 600;
  border-width: 0.15rem;
  border-radius: 2rem;
  width: 7rem;
  height: auto;

  .back-btn-icon {
    font-size: 1.5rem;
    transition: transform 0.3s ease; // Add smooth transition for the transform property
  }
}
