@import '../../../../public-scss/colors.scss';

.consultationCard {
  position: relative; // Set position to relative
  max-width: 25rem !important; /* Use max-width instead of width */
  width: 100%; /* Allow it to take the full width of the parent */
  flex-shrink: 0; // Prevent cards from shrinking when there are too many
  background: $fake-white;
  border-radius: 3rem !important;
  min-height: 30rem !important;
  height: 100%;

  h2 {
    font-size: 1.3rem !important;
    font-weight: 600;
  }
  h3 {
    font-size: 1.5rem !important;
  }

  footer,
  header {
    background-color: $accentSoft !important;
  }

  footer {
    border-radius: 0 0 4rem 4rem;
  }

  header {
    border-radius: 3rem 3rem 0 0;
    min-height: 6rem;
  }

  .lowerZone {
    position: absolute; // Set position to absolute
    bottom: 0; // Stick to the bottom
    width: 100%; // Occupy full width
    padding-top: 1rem; // Add padding if needed
  }

  button {
    transition: 0.3s;
    width: 10rem !important;
    border-radius: 3rem !important;
    background-color: $accent !important;
    border: 1px solid $accent !important;
    color: $white !important;
    font-size: 1.1rem !important;
    align-items: center;

    svg {
      font-size: 1.4rem !important;
      transition: 0.3s ease-in-out;
      transform-origin: right; // Set the transform origin to the right
    }

    &:hover {
      background-color: $white !important;
      color: $accent !important;

      svg {
        font-size: 1.5rem !important;
        color: $accent !important;
        transform: translateX(1rem); // Move the icon to the left on hover
      }
    }
  }
}
