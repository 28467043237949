@import '../../../public-scss/colors.scss';



.error-message {
    color: $red !important;
    font-weight: 600;
}

.resultBoxSupportPage{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
    width: 40rem !important;
    background: #ffffff;
    border-radius: 3rem !important;
}

.inputWrapperSupportPage{

    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.inputSupportPage{
    width: 100% !important;
}

.send-email-btn {
    &:focus {
        outline: none !important;
        box-shadow: none !important;
        background-color: $accent !important;
        border-color: $accent !important;
        color: $fake-white !important;
    }

    color: $accent;
    border-color: $accent;
    font-weight: 700;
    border-width: 0.15rem;
    border-radius: 2rem;
    width: 18rem;
    height: auto;
    margin-top: 1.5rem;
    background-color: $fake-white !important;

    .send-email-icon {
        font-size: 1.5rem;
        transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
        position: relative;
        transform: scale(1);
        opacity: 1;
    }

    &:hover {
        background-color: $accent !important;
        color: $fake-white;
        border-color: $accent;

        .send-email-icon {
            transform: scale(1.5);
            opacity: 0.8;
        }
    }

    &:disabled {
        background-color: $fake-white; // Customize disabled background color
        color: $accent; // Customize disabled text color
        border-color: $accent; // Customize disabled border color
        opacity: 0.5; // Adjust the opacity for the icon when disabled

        .send-email-icon {
            opacity: 0.5; // Adjust the opacity for the icon when disabled
            transform: scale(0.85);

        }
    }
}


