@import '../../../../public-scss/colors.scss';

.addUserModal {
  div {
    .modal-content {
      border-radius: 2rem !important;
      background: #f9f9f8 !important;
      .modal-header {
        border-bottom: none !important;
        button {
          background: none !important;
          border: none !important;
          svg {
            font-size: 1.8rem !important;
          }
        }
      }
      .modal-footer {
        .cancelAddUser {
          border-radius: 0.5rem !important;
          background: #f9f9f8 !important;
          border: 2px solid $accent !important;
          color: $accent !important;
          &:hover {
            background: $accentSofter !important;
            color: $accentDarker !important;
          }
        }
        .inviteAddUser {
          border-radius: 0.5rem !important;
          background: $accent !important;
          border: 2px solid $accent !important;
          color: #f9f9f8 !important;
          &:hover {
            background: $accentDarker !important;
          }
        }
      }
    }
  }
}
