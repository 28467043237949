@import '../../../../public-scss/colors.scss';

.customToolTip {
  background-color: $accentSoft !important;
  border-radius: 1rem;
  padding: 1rem;

  border: 1px solid $accent;
}

.usagesGraph {
  h3 {
    font-size: 1.1rem;
  }

  border-radius: 2rem;
  padding: 0.5rem;
  background-color: $fake-white;
  width: 100%;
}

#userStatus {
  text {
    font-weight: 700 !important;
    font-size: 1.5rem !important;
  }
}

.informationCard {
  background-color: $accentSoft;
  border-radius: 1.5rem;
  width: 15rem;
  min-height: 12rem;
  padding: 1rem;
  margin-top: 0.5rem;
  .informationValue {
    font-size: 3.5rem;
    font-weight: 600;
  }
  .informationText {
    font-size: 1.1rem;
  }
}
