@import '../../../public-scss/colors.scss';

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  background-color: #f0f0f0;

  @media (max-width: 768px) {
    background-color: transparent;
  }
}

#wallpaper {
  display: block;
  max-width: none;
  /* Allow image to exceed container width */
  max-height: 100%;
  width: auto;
  height: 100%;
  /* Ensure image covers the entire height */
  object-fit: contain;
  /* Maintain aspect ratio and fit image inside container */
}

/* AuthPage.scss */
.auth-page-container {
  transition: 1s !important;
  display: flex;
  height: 100vh;
  overflow: hidden;
  /* Prevent horizontal scrolling */
}

.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Align content at the top */
  align-items: center;
  /* Center content horizontally */
  width: 30rem;

  @media (max-width: 768px) {
    flex: none;
    width: 100%;

    padding: 0.5rem;
    box-sizing: border-box;
  }
}

.logoAuth {
  position: relative;
  top: 1rem;
  padding-bottom: 6rem;
  @media (max-width: 768px) {
    width: 60%;
  }
}

#firebaseui-auth-container {
  margin-top: 2rem;
  padding-bottom: 8rem;
  width: 22rem;
  @media (max-width: 768px) {
    width: 100% !important;
  }
  h1 {
    margin-top: 2rem;
    padding-bottom: 3rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 2.25rem;
  }

  .firebaseui-textfield {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-weight: 700;
      font-family: 'Roboto', sans-serif;
    }

    input {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      border-radius: 0.5rem;
      border: 2px gray solid;
      transition: 0.3s !important;

      &:focus {
        outline: none;
        box-shadow: none;
        border: 2px $accent solid;
      }
    }
  }
}

.firebaseui-error {
  color: red;
}

.firebaseui-form-actions {
  display: flex;
  justify-content: center;

  .firebaseui-id-submit {    
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    background-color: $accent;
    border: 0.1rem $accent solid;
    color: $white;
    width: 15rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 1.25rem;
    font-weight: 500;
    transition: 0.3s;

    &:hover {
      background-color: $accentDarker;
      color: $white;
      font-weight: 500;
    }
  }
  .firebaseui-id-secondary-link{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    background-color: $fake-white;
    border: 0.1rem $accent solid;
    color: $accent;
    width: 15rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 1.25rem;
    font-weight: 500;
    transition: 0.3s;

    &:hover {
      background-color: $fake-white;
      border-color: $accentDarker;
      color: $accentDarker;
      font-weight: 500;
    }
  }
}

.firebaseui-link {
  color: $accent;
  font-weight: 500;
  transition: 0.3s;

  &:hover {
    color: $accent;
  }
}

.firebaseui-id-submit:disabled {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    background-color: $accentSoft;
    border: 0.1rem $accent solid;
    color: $white;
    width: 15rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 1.25rem;
    font-weight: 500;
    transition: 0.3s;

    &:hover {
      background-color: $accentSofter;
      color: $white;
      font-weight: 500;
    }
  }