@import '../../../public-scss/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.nav-link-wrapper,
.account-wrapper {
  border-radius: 0.75rem;
  transition: 0.3s;
  width: 13rem !important;
  font-weight: 600;
  font-size: 1.2rem;

  .nav-link,
  .account {
    color: $accent !important;
  }

  svg {
    font-size: 1.5rem;
  }

  &:hover {
    background-color: $accentSofter;
  }
}

.accountSection {
  position: absolute;
  bottom: 0; /* Adjust the distance from the bottom as needed */
  width: 20rem; /* Take full width */
  .account-wrapper {
    width: 100% !important; /* Take full width */
    font-size: 1rem !important;
    &:hover {
      background-color: $fake-white !important;
    }
    .logOutBtn {
      border-radius: 0.75rem;
      color: $red;
      transition: 0.3s;
      font-size: 1.6rem !important;
      &:hover {
        background-color: $babyPink !important;
      }
    }

    .accountInfo {
      font-size: 0.75rem !important;
      border-radius: 0.75rem;
      transition: 0.3s;
      &:hover {
        background-color: $accentSofter;
      }
    }
  }
}

.btn-icon {
  margin: 0.2rem;
}

.version_lbl {
  background-color: $accent; /* Accent color */
  color: $fake-white; /* White text color */
  padding: 0.15rem 0.5rem; /* Adjust padding as needed */
  border-radius: 0.25rem; /* Rounded corners */
  font-weight: bold;
  display: inline-block;
  margin-top: 0.5rem; /* Add margin for spacing */
  opacity: 1; /* Adjust opacity for subtlety */
  font-family: 'Roboto', sans-serif;
}

.account {
  cursor: pointer;
}
.toggle-btn {
  cursor: pointer;
  position: absolute;
  top: 5rem;
  left: 20rem;
  transform: translateX(-50%);
  background-color: $fake-white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 2px solid lightgray;
  color: $accent;
}

.left-sidebar {
  z-index: 20000 !important;
  background-color: $fake-white;
  height: 100vh; /* 100% of the viewport height */
  width: 20rem; /* Set the desired width */
  position: fixed; /* Fixed position to stay on the left side */
  left: 0; /* Stick to the left */
  top: 0; /* Align to the top */
  border-right: solid lightgray 1px !important;
  border-radius: 0 1rem 1rem 0;
  hr {
    border: lightgray solid 1px !important;
  }

  h2 {
    text-align: left !important;
    margin-left: 3rem !important;
    font-size: 1.2rem;
  }

  &.minimized {
    width: 4rem; // Set the desired width for the minimized sidebar
    img {
      max-width: 2rem !important; // Set the desired max-width for the minimized logo
    }
    h2,
    .textPart {
      display: none !important;
    }
    .toggle-btn {
      left: 4rem;
      top: 3.6rem;
    }

    .nav-link-wrapper {
      width: 2rem !important; // Set the desired width for the minimized nav links
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      .nav-link {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
    .accountSection {
      width: 100% !important;
      margin: 0;
      .account-wrapper {
        width: 100% !important;

        .logOutBtn {
          padding: 0.25rem !important;

          &:hover {
            background-color: $babyPink !important;
          }
        }

        .accountInfo {
          padding: 0.25rem !important;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
          &:hover {
            background-color: $accentSofter;
          }
        }
      }
    }
  }

  @media (max-width: 1787px) {
    &.minimized {
      width: 3rem; // Adjust width for smaller screens if needed
      img {
        max-width: 1.5rem; // Adjust max-width for smaller screens if needed
      }
      .toggle-btn {
        top: 3.6rem;
        left: 3rem;
      }
      .accountSection {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 500px) {
    display: block; // Show minimized version when minimized
    width: 18rem; // Set the desired width for the minimized sidebar on small screens
    .accountSection {
      width: 100% !important;
    }
    .toggle-btn {
      left: 18rem;
    }
    &.minimized {
      .otherThanToggleBtn {
        display: none !important;
      }
      width: 1rem !important;

      .accountSection {
        width: 100% !important;
      }
      img {
        display: none; // Hide logo in minimized state on small screens
      }

      .toggle-btn {
        display: inline-block !important;
        position: absolute !important;
        top: 3rem !important;
        left: 1rem;
      }
    }
  }
}
