@import '../../../../public-scss/colors.scss';

.go-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  display: none;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity 0.3s;
}

.go-to-top-button.show {
  display: block;
}

.go-to-top-button {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: $accent !important;
    border-color: $accent !important;
    color: $fake-white !important;
  }

  background-color: $fake-white;
  color: $accent;
  border-color: $accent;
  border: solid;
  transition: 0.3s;
  font-weight: 700;
  border-width: 0.15rem;
  border-radius: 2rem;
  width: 7rem;
  height: auto;

  .go-to-top-button-icon {
    font-size: 1.5rem;
    position: relative;
    left: 1rem;
  }

  &:hover {
    background-color: $accent;
    color: $fake-white;
    border-color: $accent;
  }
}
