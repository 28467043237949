@import '../../../public-scss/colors.scss';

.historyContainer {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
  max-width: 70rem !important; /* Use max-width instead of width */
  width: 100%; /* Allow it to take the full width of the parent */
  background: $white;
  border-radius: 3rem !important;
}

.cardContainer {
  display: flex;
  gap: 10px; /* Adjust as needed for spacing between cards */
  overflow-x: auto; /* Enable horizontal scrolling */
  margin-bottom: 20px; /* Adjust as needed for spacing */
}

@media (max-width: 767px) {
  .historyContainer {
    h1 {
      font-size: 1.8rem;
    }
  }
}
