@import '../../../../public-scss/colors.scss';

$lightRed: #ffaaaa;

#accountPopup {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 100vh; /* This ensures the container takes up the full viewport height */
  .modal-content {
    border-radius: 2rem !important;
    background: #f9f9f8 !important;
    .modal-header {
      border-bottom: none !important;
      button {
        background: none !important;
        border: none !important;
        svg {
          font-size: 1.8rem !important;
        }
      }
    }
  }
}

.editProfile_btn,
.saveChanges_btn {
  background-color: $accent;
  color: $white;
  border: 1px solid $accent;
  svg {
    font-size: 1.35rem;
    transform: translateX(0);
    transition: transform 0.3s; /* Add a transition for the SVG */
  }

  &:hover,
  &:focus {
    background-color: $accentDarker !important;
    color: $fake-white !important;
    border-color: $accentDarker !important;

    svg {
      transform: translateX(75%);
    }
  }
}

.Close_btn {
  background-color: $fake-white;
  color: $accent;
  border: 1px solid $accent;

  svg {
    font-size: 1.35rem;
    transform: translateX(0);
    transition: transform 0.3s; /* Add a transition for the SVG */
  }

  &:hover,
  &:focus {
    background-color: $lightRed !important;
    color: $fake-white !important;
    border-color: $lightRed !important;

    svg {
      transform: translateX(75%);
    }
  }
}

.userProfileTextFieldForm {
  box-shadow: none;
  border-width: 2px;
  border-radius: 0.5rem;
  width: 75%;
  margin-bottom: 5px;
  &:focus {
    outline: none !important;
    border-color: $accent !important;
    box-shadow: 0 0 1px 1px $accent !important;
  }
}
