@import './public-scss/colors.scss';

.bg-fake-white {
  background-color: $fake-white;
}

body,
html {
  background-color: $fakeWhite;
  min-height: 100vh !important;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
