@import '../../../public-scss/colors.scss';

.welcomeContainer {
  background-color: $fake-white;
  max-width: 50rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 2rem;
  border-radius: 1.5rem;
  border: dashed 0.2rem $accent;

  h1 {
    font-family: 'Roboto', sans-serif;
  }
  div {
    h4 {
      cursor: pointer; /* Add cursor pointer for the "Show More" link */
      text-decoration: underline;
      color: $accent;
    }
    /* Add transition properties for a smooth slide animation */
    .show-more-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.6s;
    }
    .show-more-content.show {
      max-height: 30rem; /* Adjust the max-height based on your content */
      transition: max-height 0.6s;
    }

    div {
      button {
        transition: 0.3s;
        border-radius: 1rem;
        width: 15rem;
        height: 3rem;
        font-size: 1.25rem;
        border: 0.2rem solid $accent;
        background: $accent;
        color: $fake-white;
        svg {
          font-size: 2rem;
        }
        &:hover {
          background: $fake-white;
          color: $accent;
        }
        &:disabled {
          background: $accentSofter;
          color: $accentDarker;
        }
      }
      input {
        width: 100%;
        max-width: 28rem;
        height: 2.5rem;
        border-radius: 0.75rem;
        border: 0.2rem dashed $accent;
        &:focus {
          outline: none !important;
        }
      }
      div {
        img {
          width: 97%;
          border-radius: 2.25rem;
        }
      }
    }
  }
}

.signOutLinking {
  cursor: pointer;
  text-decoration: none !important;
  background-color: $fake-white;
  border: 2px solid $accent;
  color: $accent !important;
  border-radius: 1rem !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 10rem !important;
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 0.2rem !important;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: $accent;
    color: $fake-white !important;
  }
}
